"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const getMinWeightForProperBMI = (height, minBMI = 20) => Math.floor(height ** 2 * minBMI * 10) / 10;
exports.default = {
    getRoundedBmi(weightInKilos, heightInCms) {
        const heightInMeters = heightInCms / 100;
        const bmi = weightInKilos / (heightInMeters * heightInMeters);
        const roundedBmi = Math.round(bmi * 10) / 10;
        return roundedBmi.toFixed(1);
    },
    getMinimumWeight(heightInCms) {
        const heightInMeters = heightInCms / 100;
        const minBMI = 20;
        const minPossibleWeight = 30;
        return Math.max(Math.ceil(getMinWeightForProperBMI(heightInMeters, minBMI)), minPossibleWeight);
    }
};
